.jform-box {
    --form-color: #{$text-color};
    --form-info-fs: #{$fs-regular};
    --form-err-fs: #{$fs-regular};
    --form-border-thickness: 2px;
    --form-group-mb: #{$space-s};

    --form-ctrl-bg: #{$surface-300};
    --form-ctrl-color: #{$text-color};
    --form-ctrl-p: #{$space-xs};

    --form-checkbox-bg: #{$surface-300};
    --form-checkbox-size: #{$space-l};

    color: var(--form-color);
    user-select: none;
}

.jform {
    color: var(--form-color);

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s;
        color: black !important;
        -webkit-text-fill-color: var(--form-ctrl-color) !important;
        -webkit-box-shadow: 0 0 0 1000px var(--form-ctrl-bg) inset !important;
        -webkit-background-clip: text !important;
        background-clip: text !important;
    }

    hr {
        border-top: var(--form-border-thickness) solid $surface-300;
    }

    .errors {
        font-size: var(--form-err-fs);
        text-align: center;
        padding: #{$space-s} #{$space-xxs};
        border: var(--form-border-thickness) dashed $text-error;
        color: $text-light;

        span {
            color: $text-light;
            font-weight: bold;
            display: block;
            margin-top: #{$space-s};
        }

        & span:first-child {
            margin: 0;
        }

        span:before {
            content: '!';
            color: $text-color;
            background-color: $text-error;
            padding: 0 #{$space-xs};
            border-radius: 50%;
            margin-right: #{$space-xxs};
            font-weight: bold;
        }
    }
}

.jform-group {
    margin-bottom: var(--form-group-mb);
    //overflow:      hidden;
}

.jform-label {
    margin: 0;
}

.jform-input {
    width: 100%;
    appearance: none;
    box-shadow: none;
    background-image: none;
    background-color: var(--form-ctrl-bg);
    color: var(--form-ctrl-color);
    padding: var(--form-ctrl-p);
    border: 0px none transparent;
}

.jform-text-divider {
    display: flex;
    align-items: center;

    .text {
        padding-inline: 1rem;
    }

    .line {
        flex: 1;
        height: var(--form-border-thickness);
        background-color: $surface-300;
    }
}

.jform-group--checkbox {
    display: flex;
    //align-items: center;

    a:hover {
        color: $text-color;
    }

    .check-box {
        width: var(--form-checkbox-size);
        height: var(--form-checkbox-size);
        margin: 0px;
        display: block;
    }

    .remember {
        padding-left: calc(var(--form-checkbox-size) + #{$space-xxs});
        font-size: 14px;
        user-select: none;
        margin: 0;
        line-height: 1.1;
        text-wrap: balance;
    }
}

.check-box {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
}

.check-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
}

.checkmark {
    position: absolute;
    width: var(--form-checkbox-size);
    height: var(--form-checkbox-size);
    background-color: var(--form-checkbox-bg);
    top: 0;
    left: 0;

    &:after {
        content: '';
        position: absolute;
        left: 8px;
        top: 2px;
        width: 10px;
        height: 15px;
        border: solid var(--form-color);
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
    }
}

.check-box:hover input ~ .checkmark:after {
    opacity: 0.2;
}

.check-box input:checked ~ .checkmark:after {
    opacity: 1;
}

@media (min-width: 992px) {
}

//.pass-text {
//    color: #000;
//}
//.jform {
//    color: $text-color;
//

//
//    .jform-group--recaptcha {
//        position: relative;
//        padding-bottom: 80px;
//
//        .g-recaptcha {
//            position: absolute;
//            left: 0;
//            top: 0;
//            width: 100%;
//            height: 100%;
//        }
//    }
//
//    @media (min-width: 768px) {
//        .jform-title {
//            font-size: 30px;
//        }
//    }
//    @media (min-width: 992px) {
//    }
//    @media (min-width: 1200px) {
//    }
//}
//

//
//.jform-select {
//    width: 100%;
//    appearance: none;
//    background-image: none;
//    background-color: $surface-300;
//    border: 0px solid transparent;
//    padding: 5px;
//    color: $text-color;
//}
//
//.jform-label {
//}
//
//.jform-bottom-message {
//    font-size: 16px;
//}
//
//.jform-plain-link {
//    color: $text-reverse;
//}
//.jform-group--checkbox input:not(:checked),
//.jform-group--checkbox input:checked {
//    position: absolute;
//    left: -9999px;
//}
//.jform-group--checkbox input:not(:checked) + label,
//.jform-group--checkbox input:checked + label {
//    font-size: 14px;
//    color: rgba(255, 255, 255, 0.6);
//    font-weight: normal;
//    position: relative;
//    cursor: pointer;
//    padding-left: 35px;
//    line-height: 20px;
//    letter-spacing: 0.4px;
//    margin: 0;
//}
//.jform-group--checkbox input:not(:checked) + label a,
//.jform-group--checkbox input:checked + label a {
//    color: $text-highlight;
//}
//.jform-group--checkbox input:not(:checked) + label a:hover,
//.jform-group--checkbox input:checked + label a:hover {
//    color: $text-highlight;
//}
//.jform-group--checkbox input:not(:checked) + label:before,
//.jform-group--checkbox input:checked + label:before {
//    content: '';
//    position: absolute;
//    left: 0;
//    top: 0;
//    width: 20px;
//    height: 20px;
//    background-color: rgba(210, 201, 255, 0.04);
//    border-radius: 6px;
//}
//.jform-group--checkbox input:not(:checked) + label:after,
//.jform-group--checkbox input:checked + label:after {
//    font-family: 'Ionicons';
//    content: '\f3ff';
//    position: absolute;
//    left: 0;
//    top: 0;
//    width: 20px;
//    height: 20px;
//    font-size: 22px;
//    line-height: 20px;
//    text-align: center;
//    -webkit-transition: 0.4s;
//    -moz-transition: 0.4s;
//    transition: 0.4s;
//    color: $text-highlight;
//}
//.jform-group--checkbox input:not(:checked) + label:after {
//    opacity: 0;
//    -webkit-transform: scale(0);
//    -moz-transform: scale(0);
//    transform: scale(0);
//}
//.jform-group--checkbox input:checked + label:after {
//    opacity: 1;
//    -webkit-transform: scale(1);
//    -moz-transform: scale(1);
//    transform: scale(1);
//}
//.jform-group--checkbox label::-moz-selection {
//    background: transparent;
//    color: rgba(255, 255, 255, 0.6);
//}
//.jform-group--checkbox label::selection {
//    background: transparent;
//    color: rgba(255, 255, 255, 0.6);
//}
//

//// select 2
//.select2-container .select2-selection--single {
//    height: 34px;
//}
//.select2-container--default .select2-selection--single {
//    //background-color: $black-background;
//    border: 0px none transparent;
//    border-radius: 0px;
//    padding: 2px 0;
//}
//
//.select2-container--default .select2-selection--single .select2-selection__arrow b {
//    border-color: $text-color transparent transparent transparent;
//}
//
//.select2-container--default .select2-selection--single .select2-selection__arrow b,
//.select2-container--default .select2-selection--single .select2-selection__rendered,
//.select2-container--default .select2-selection--single .select2-selection__placeholder {
//    color: $text-color;
//}
//.select2-container--default .select2-selection--single .select2-selection__rendered {
//    font-size: 16px;
//    padding-left: 5px;
//}
//
//.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
//    //background-color: $grigio-scuro;
//}
//.select2-results__options {
//    font-size: 16px;
//}
//
//.select2-results__option.select2-results__option--selectable {
//    border-bottom: 1px dashed;
//}
//
//.select2-dropdown {
//    color: $text-reverse;
//    background-color: $grigio-chiaro;
//}
