$main-font-family: 'Kanit';
//$alt-font-family:        'sans serif';

$space-xxs: 5px;
$space-xs: 10px;
$space-s: 15px;
$space-m: 20px;
$space-l: 25px;
$space-xl: 50px;
$space-xxl: 75px;

$fs-small: 14px;
$fs-regular: 16px;
$fs-medium: 18px;
$fs-medium-large: 20px;
$fs-large: 24px;
$fs-big: 30px;
$fs-huge: 36px;
$fs-wide: 46px;

$fs-base: $fs-regular;
//$blue:    #007bff;
//$indigo:  #6610f2;
//$purple:  #6f42c1;
//$pink:    #e83e8c;
//$red:     #dc3545;
//$orange:  #fd7e14;
//$yellow:  #ffc107;
//$green:   #28a745;
//$teal:    #20c997;
//$cyan:    #17a2b8;

$primary: #00e08f;
//$secondary:     $gray-600;
//$success:       $green;
//$info:          $cyan;
//$warning:       $yellow;
//$danger:        $red;
//$light:         $gray-100;
//$dark:          $gray-800;

$black: #000;
$white: #fff;

// https://colorffy.com/dark-theme-generator ( #108FE3, #11090A )
/** SCSS DARK THEME MIXED SURFACE COLORS */
$surface-100: #0c1218;
$surface-200: #222f45;
$surface-300: #384358;
$surface-400: #4e586b;
$surface-500: #666e7e;
$surface-600: #7e8493;
$surface-700: #969ba7;
$surface-800: #b6bfcc;
$surface-900: #e4e6eb;

$surface-alpha-100: rgba($surface-100, 0.2);
$surface-alpha-200: rgba($surface-200, 0.2);
$surface-alpha-300: rgba($surface-300, 0.2);
$surface-alpha-400: rgba($surface-400, 0.2);
$surface-alpha-500: rgba($surface-500, 0.2);
$surface-alpha-600: rgba($surface-600, 0.2);

$text-light: $surface-900;
$text-color: $surface-900;
$text-reverse: $surface-200;
$text-highlight: $primary;
$text-error: red;
$text-black: purple;
$text-dark: fuchsia;
$text-form-color: violet;
$text-disabled: pink;

$bg-surface: $surface-100;
$bg-surface-gradient: linear-gradient(135deg, $surface-200 0%, $black 100%);

$link-color: $primary;

$checkbox-color: $text-color;
$checkbox-bg: $surface-200;

$selection-background: $primary;
$selection-color: $surface-100;

$placeholder-color: $surface-400;
$placeholder-weight: normal;
$placeholder-font-size: 16px;
$placeholder-font-style: normal;

:root {
    --fs-small: #{$fs-small};
    --fs-regular: #{$fs-regular};
    --fs-medium: #{$fs-medium};
    --fs-medium-large: #{$fs-medium-large};
    --fs-large: #{$fs-large};
    --fs-big: #{$fs-big};
    --fs-huge: #{$fs-huge};
    --fs-wide: #{$fs-wide};

    --link-color: #{$link-color};
    --text-color: #{$text-color};
    --text-highlight: #{$text-highlight};

    --space-xxs: #{$space-xxs};
    --space-xs: #{$space-xs};
    --space-s: #{$space-s};
    --space-m: #{$space-m};
    --space-l: #{$space-l};
    --space-xl: #{$space-xl};
    --space-xxl: #{$space-xxl};

    --main-logo-size: 200px;
}

// Fede
$main-header-bg: #30323f;

//$giallo:                 #FFD500; //rgb(255, 213, 0)
//$nero:                   #000;
//$nero-medio:             #101820;
//$nero-scuro:             #0D131A;
//$nero-chiaro:            #16212C;
//$bianco:                 #FFF;
//$rosso:                  #FE0000;
//
//$bianco-rosa:            #FFD5FF;
//$grigio:                 rgb(87, 87, 86);
//$grigio-scuro:           rgb(88, 88, 90);
//$grigio-medio:           rgb(136, 139, 179);
//$grigio-chiaro:          rgb(216, 216, 218);
//$bianco-sporco:          rgb(246, 246, 246);
//$arancione:              rgb(242, 99, 52);
//
//
//$main-header-bg:         transparent;
//$main-header-color:      $bianco;
//
//
//$background-color:       #002D56;
//$background-highlight:   $rosso;
//$background-reverse:     $bianco;
//

//
//$dark-background:        rgba(0, 0, 0, 0.5);
//$light-background:       rgba(255, 255, 255, 0.7);
//$blue-background:        rgba(0, 56, 101, 0.95);
//$white-background:       rgb(255, 255, 255);
//$black-background:       rgb(0, 0, 0);
//
//// AV LIVE BACKGROUNDS
//$av-live-background:     transparent;
//$av-content-background:  transparent;
//$av-aside-background:    transparent;
//$room-box-background:    $white-background;
//$av-live-frame-border:   $dark-background;
//
//
//$msg-confirm-color:      $text-highlight;
//$msg-confirm-bg:         $nero;
//
//$button-color:           $bianco;
//
//$star-color:             gold;
//
//$survey-disabled-arrow:  #5C5C5C;
//
//$text-strong:            bold;
//$text-bold:              700;
//$text-semibold:          600;
//$text-regular:           400;
//$text-light:             300;
//$text-thin:              200;
//
//.dummy  {
//	color: rgba(255, 255, 255, 0.1);
//}
//
//@media (min-width: 950px) {
//}
//
///*
//
//$text-reverse: #212121;
//$text-inverse: #eeeeee;
//$text-highlight: $color-2;
//$text-error: red;
//
//$placeholder-color: rgba(143, 143, 143, 0.6);
//
//
//*/
//
///**
// * ----------------------------------------
// * animation slide-in-fwd-bottom
// * ----------------------------------------
// */
//@-webkit-keyframes slide-in-fwd-bottom {
//	0% {
//		-webkit-transform: translateZ(-1400px) translateY(100%);
//		transform:         translateZ(-1400px) translateY(100%);
//		opacity:           0;
//	}
//	100% {
//		-webkit-transform: translateZ(0) translateY(0);
//		transform:         translateZ(0) translateY(0);
//		opacity:           1;
//	}
//}
//
//@keyframes slide-in-fwd-bottom {
//	0% {
//		-webkit-transform: translateZ(-1400px) translateY(100%);
//		transform:         translateZ(-1400px) translateY(100%);
//		opacity:           0;
//	}
//	100% {
//		-webkit-transform: translateZ(0) translateY(0);
//		transform:         translateZ(0) translateY(0);
//		opacity:           1;
//	}
//}
