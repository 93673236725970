#header {
    position: sticky;
    top: 0;
    z-index: 50000;
    border-bottom: 1px solid darken(#30323f, 2%);
}

.nav-logo img {
    height: 4rem;
    background: $main-header-bg;
    padding: 1rem 0;
}
