@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $size, $length in $spacers {
            @if '#{$size}' != '0' {
                .gap#{$infix}-#{$size} {
                    gap: $length !important;
                }
                .column-gap#{$infix}-#{$size} {
                    column-gap: $length !important;
                }
                .row-gap#{$infix}-#{$size} {
                    row-gap: $length !important;
                }
            }
        }
    }
}
