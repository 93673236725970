.bg-surface {
    background-color: $bg-surface;
}

.bg-surface-gradient {
    background: $bg-surface-gradient;
}

#view {
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;

    &.vertical {
        display: flex;
        flex-direction: column;
    }

    &.horizontal {
        display: flex;
        flex-direction: row;
    }

    &.main-scroll {
        height: 100dvh;
        height: 100vh;
        overflow: hidden;

        #main {
            overflow-y: scroll;
            align-items: flex-start;
        }
    }
}

#main {
    flex-grow: 1;
    padding: 1rem;

    //&.main-content-h {
    //    display: flex;
    //    flex-direction: column;
    //    min-height: 100%;
    //}
    //&.main-content-v {
    //    display: flex;
    //    flex-direction: column;
    //    justify-content: center;
    //    align-items: center;
    //    height: 100%;
    //}
    //&.main-content-vl {
    //    display: flex;
    //    flex-direction: column;
    //    justify-content: center;
    //    height: 100%;
    //}
}

.main-aside-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-content {
    flex-grow: 1;
}

.main-logo {
    max-width: var(--main-logo-size);
    margin-inline: auto;
}

#aside-image {
    display: none;
}

.box {
    --max-width: 100%;

    &.box-small {
        --max-width: 400px;
    }

    &.box-medium {
        --max-width: 768px;
    }

    &.box-large {
        --max-width: 935px;
    }

    &.box-big {
        --max-width: 1200px;
    }

    &.box-full {
        flex-grow: 1;
    }

    margin-inline: auto;
    max-width: min(var(--max-width), 100%);
    width: 100%;
}

@media (min-width: 1100px) {
    #aside-image {
        display: flex;
        flex-basis: 40%;

        img {
            object-fit: cover;
        }
    }
}

@media (min-width: 1400px) {
}

@media (min-width: 1400px) {
}

@media (min-width: 1600px) {
}
