.btn-form-submit {
    color: $text-color;
    background-color: $text-reverse;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 26px;
    margin: 0 auto;
    text-align: center;
    font-weight: bold;
    // border: 1px solid $text-highlight;

    &:hover,
    &:active {
        font-weight: bold;
        color: $text-color;
        // background-color: $text-highlight;
        // border: 1px solid $text-highlight;
    }

    &:focus,
    &:active {
        transform: scale(0.95);
        // color: $text-reverse;
        // background-color: $text-highlight;
        // border: 1px solid $text-highlight;
    }
}

.btn-sso {
    --border-color: var(--form-ctrl-bg, #{$surface-300});
    --border-hover-color: var(--form-ctrl-color, #{$text-color});

    --icon-size: 20px;
    --text-color: #{$text-color};

    display: flex;
    justify-content: center;
    border: 1px solid var(--border-color);
    padding: #{$space-xs};
    transition: all 0.3s ease-in-out;

    &:hover {
        border-color: var(--border-hover-color);
        transition: all 0.3s ease-in-out;
    }

    .icon {
        width: var(--icon-size);
    }

    .text {
        padding-left: 1rem;
        color: var(--text-color);
    }
}

// buttons for languages
//ul.header__languages.d-flex {
//    justify-content: end;
//    margin-bottom: 15px;
//    @media screen and (min-width: 980px) {
//        justify-content: center;
//        margin-bottom: 0;
//    }
//
//    li {
//        margin: 0 0 0 10px;
//        @media screen and (min-width: 980px) {
//            margin: 0 10px;
//        }
//
//        div {
//            color: white;
//            display: flex;
//            align-items: center;
//            justify-content: center;
//            cursor: pointer;
//            width: 30px;
//            height: 25px;
//            background-size: 30px;
//            background-repeat: no-repeat;
//            background-position: center center;
//            opacity: 0.5;
//            &.lang-active {
//                opacity: 1 !important;
//            }
//            &:hover {
//                opacity: 1 !important;
//            }
//        }
//    }
//}
