.text-color {
    color: $text-color;
}

.text-highlight {
    color: $text-highlight;
}

.text-reverse {
    color: $text-reverse;
}

.text-error {
    color: $text-error;
}

.text-strong {
    font-weight: bold;
}

.text-bold {
    font-weight: 700;
}

.text-semibold {
    font-weight: 600;
}

.text-regular {
    font-weight: 400;
}

.text-light {
    font-weight: 300;
}

.text-thin {
    font-weight: 200;
}

.fs-small {
    font-size: var(--fs-small);
}

.fs-regular {
    font-size: var(--fs-regular);
}

.fs-medium {
    font-size: var(--fs-medium);
}

.fs-medium-large {
    font-size: var(--fs-medium-large);
}

.fs-large {
    font-size: var(--fs-large);
}

.fs-big {
    font-size: var(--fs-big);
}

.fs-huge {
    font-size: var(--fs-huge);
}

.fs-wide {
    font-size: var(--fs-wide);
}

.bg-surface-100 {
    background-color: $surface-100;
}
.bg-surface-200 {
    background-color: $surface-200;
}
.bg-surface-300 {
    background-color: $surface-300;
}
.bg-surface-400 {
    background-color: $surface-400;
}

.link-color {
    color: var(--link-color);
}

.text-balance {
    text-wrap: balance;
}
.text-underline {
    text-decoration: underline;
}
.text-upper {
    text-transform: uppercase;
}

.alt-font {
    //    font-family: $alt-font-family;
}

.bg-highlight {
    background-color: $text-highlight;
}

.bg-white {
    //    background-color: $white-background;
}

.line-divider {
    border-top: 3px solid $text-color;
    height: 3px;
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}

.fade {
    transition: opacity 0.25s linear;
}

.hilink {
    color: var(--link-color);
}

.undermove {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0px;
        left: 0;
        background-color: var(--link-color);
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
    }

    &:hover:before {
        visibility: visible;
        transform: scaleX(1);
    }
}

@media screen and (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

@media (min-width: 992px) {
    .to-992 {
        display: none;
    }
}

@media (max-width: 991px) {
    .from-992 {
        display: none;
    }
}
